/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";
@import "~animate.css/animate.css";
/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

ion-text {
    font-family: 'MontserratExtraBold';
}
ion-header {
    ion-toolbar {
        --background: var(--ion-color-light);
    }
}
.bottomCard {
    margin: 10px 20px;

    ion-col {
        height: 90px;
        padding: 0px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
        }
    }

    .mid {
        padding: 15px 0px 15px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .title {
            color: #000;
            font-size: 12px;
            font-weight: 700;
        }

        .desc {
            color: #000;
            font-size: 12px;
            font-weight: 400;
        }
    }

    .right {
        display: flex;
        align-items: center;

        ion-icon {
            font-size: 20px;
        }
    }
}